import React from "react"
import styled from "styled-components"
import Section from "../Section"

const IssuesSection = () => {
  return (
    <SectionIssues id={"issues"}>
      <div className="container is-widescreen">
        <div className="columns is-vcentered">
          <div className="column is-6-desktop is-offset-3-desktop">
            <div className="content is-medium">
              <IssuesTitle>
                Czy narodziny dziecka <br /> to tylko strach i ból? <br />
                Oczywiście że nie!
              </IssuesTitle>
              <p className="has-text-weight-bold">
                Dziecko i poród to wielka niewiadoma?
              </p>
              <p>
                Dla większości z nas - poród i opieka nad maluszkiem to wielka
                niewiadoma. Słuchamy koleżanek, które opowiadają jak ciężko im
                było, ile trwał poród i jak mocne były skurcze. Ile miały
                nieprzespanych nocy, i jak trudno było, gdy dziecko miało kolkę.
              </p>
              <p className="has-text-weight-bold">
                Zamień strach na wiedzę i spokój
              </p>
              <p>
                To wszystko sprawia, że rosną w nas obawy i strach. Ważymy za i
                przeciw cesarskiemu cięciu, zastanawiamy się, czy damy radę,
                boimy się komplikacji!
              </p>
              <p>
                To naturalne. I właśnie dlatego otworzyłam moją wirtualną szkołę
                rodzenia – chcę, abyście strach zastąpili wiedzą, która daje
                spokój.
              </p>
              <IssueLastText className="has-text-centered">
                Narodziny ukochanego <br /> maluszka mogą być <br />{" "}
                najpiękniejszym wspomnieniem. <br />
                <span className="has-text-weight-bold">
                  Zrobimy wszystko, aby był to Twój wyjątkowy moment
                </span>
              </IssueLastText>
            </div>
          </div>
        </div>
      </div>
    </SectionIssues>
  )
}

export default IssuesSection

const SectionIssues = styled(Section)`
  padding: 70px 20px 20px 20px;
  background-color: #f8f7fa;
  color: #494260;
  margin-top: -70px;
`

const IssuesTitle = styled.p`
  font-family: Helvetica, sans, sans-serif;
  color: #494260;
  font-weight: bold;
  font-size: 1.2em;
  line-height: 1.2em;
  font-style: italic;

  @media screen and (min-width: 769px) {
    text-align: center;
  }
`

const IssueLastText = styled.p`
  font-family: Helvetica, sans, sans-serif;
  font-style: italic;
  font-size: 1.1em;
  line-height: 1.1em;

  & span {
    display: inline-block;
    margin-top: 15px;
  }
`
