import React from "react"
import styled from "styled-components"
import Section from "../Section"
import ShortDivider from "../ShortDivider"
import {
  faMobileAlt,
  faCouch,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons"
import { faGem } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { VisibilityTracking } from "../../../pages/szkola-rodzenia-z-anna-nowak-duda"

const CourseAdvantagesSection = styled(Section)`
  background-color: rgb(248, 247, 250);
  color: rgb(73, 66, 96);
  text-align: center;
  padding: 20px;
`

const Subsection = styled.div`
  margin-top: 40px;

  & .title {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

const CourseAdvantages = () => {
  return (
    <CourseAdvantagesSection id="course-advantages">
      <div className="container is-widescreen">
        <div className="columns is-mobile">
          <div className="column is-6-desktop is-offset-3-desktop is-12-mobile">
            <ShortDivider />
            <h1 className="title is-4 is-spaced has-text-font-comfortaa">
              Jak w praktyce wygląda kurs online?
            </h1>
            <div className="content is-medium">
              <Subsection className="icon-header has-text-centered">
                <FontAwesomeIcon
                  size={"2x"}
                  className={"has-text-primary"}
                  icon={faGem}
                />
                <div className="title is-5 has-text-primary">
                  JAKOŚĆ SZKOŁY LEPSZA NIŻ „NA ŻYWO”
                </div>
              </Subsection>
              <p>
                Zastanawiasz się pewnie "Czy kurs online jest dla mnie?” „Czy
                stacjonarna szkoła rodzenia nie była by lepszym rozwiązaniem?"
              </p>
              <p>Doskonale Cię rozumiem.</p>
              <p>
                Miałam podobne rozterki przed rozpoczęciem prac nad tym kursem.
                Z doświadczenia jednak wiem, że potrafię przekazać Ci taką samą
                wiedzę jak na żywo podczas kursu stacjonarnego. A nawet więcej -
                podczas nagrań lekcji nikt się nie spóźniał, nikomu nie dzwonił
                telefon, a Ja nie miałam gorszego dnia ;)
              </p>

              <Subsection className="icon-header has-text-centered">
                <FontAwesomeIcon
                  size={"2x"}
                  className={"has-text-primary"}
                  icon={faMobileAlt}
                />
                <div className="title is-5 has-text-primary">
                  ODBYWASZ KURS, KIEDY TY MASZ CZAS
                </div>
              </Subsection>

              <p>
                Po zapisaniu się na kurs otrzymasz dostęp do platformy kursowej,
                na której będziesz miała od razu dostępne wszystkie lekcje.
              </p>
              <p>
                Dostęp do materiałów masz na zawsze. Lekcje możesz oglądać na
                telefonie lub komputerze. Sama lub z partnerem. Wtedy kiedy masz
                czas i jak Ci wygodnie.
              </p>
              <p>
                <strong>
                  Teraz to kurs dostosowuje się do Ciebie, a nie odwrotnie.
                </strong>
              </p>

              <Subsection className="icon-header has-text-centered">
                <FontAwesomeIcon
                  size={"2x"}
                  className={"has-text-primary"}
                  icon={faCouch}
                />
                <div className="title is-5 has-text-primary">
                  PO PROSTU WYGODA
                </div>
              </Subsection>

              <p>
                Kurs możesz przerobić nawet na początku ciąży, a potem wracać do
                lekcji, gdy tylko poczujesz taką potrzebę. Jest to super sprawa
                zwłaszcza, że moduły{" "}
                <span className="has-text-weight-bold">
                  Kobieta po porodzie
                </span>
                ,{" "}
                <span className="has-text-weight-bold">Karmienie piersią</span>{" "}
                i{" "}
                <span className="has-text-weight-bold">
                  Opieka nad noworodkiem
                </span>{" "}
                przydają się po dłuższym czasie od uczestnictwa w kursie.
              </p>
            </div>
          </div>
        </div>
      </div>
    </CourseAdvantagesSection>
  )
}

export default CourseAdvantages
