import React, { useState } from "react"
import ShortDivider from "../ShortDivider"
import Section from "../Section"
import styled from "styled-components"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ModulesSection = styled(Section)`
  background-color: rgb(255, 251, 250);
  color: rgb(73, 66, 96);
  padding: 20px;
`

const ModuleHeader = styled.div`
  border-top: 1px solid rgb(245, 86, 145);
  border-right: 1px solid rgb(245, 86, 145);
  border-radius: 3px;
  padding: 20px 20px 20px 10px;
`

const SingleModule = styled.div`
  & ul {
    & li {
      padding: 20px 20px 20px 10px;

      & span {
        color: rgb(73, 66, 96);
        font-size: 0.8em;
      }

      & h4 {
        margin-bottom: 15px;
        margin-top: 5px;
      }

      & p {
        font-size: 0.9em;
        color: rgb(73, 66, 96);
        padding: 0px;
      }
    }
  }

  & span {
    color: rgb(245, 86, 145);
    font-size: 0.8em;
  }

  & h3 {
    color: rgb(33, 25, 59);
  }
`

const DottedDivider = styled.div`
  height: 0px;
  background: rgb(238, 231, 234);
  border-bottom: 2px dotted rgb(238, 231, 234);
  margin: 0px 20px;
`

const CollapsedLesson = ({
  number,
  title,
  description,
}: {
  number: string
  title: string
  description: string
}) => {
  const [expanded, expand] = useState(false)
  return (
    <li>
      <span>{`Lekcja ${number}: `}</span>
      <h4
        className="title is-5 is-spaced has-text-black"
        style={{ cursor: !expanded ? "pointer" : "auto" }}
        onClick={() => expand(true)}
      >
        {title}{" "}
        {!expanded && (
          <FontAwesomeIcon
            size={"sm"}
            className={"has-text-primary"}
            icon={faChevronDown}
          />
        )}
      </h4>
      {expanded && <p>{description}</p>}
    </li>
  )
}

const Modules = () => {
  return (
    <ModulesSection id="modules">
      <ShortDivider />
      <div className="container is-widescreen">
        <h1 className="title is-4 is-spaced has-text-font-comfortaa has-text-centered">
          Czego dokładnie nauczysz się z kursu?
        </h1>

        <div className="columns">
          <div className="column is-6-desktop is-offset-3-desktop is-12-mobile">
            <SingleModule>
              <ModuleHeader>
                <span>Moduł I</span>
                <h3 className="title is-4 is-spaced">
                  Przygotowanie do porodu
                </h3>
              </ModuleHeader>
              <ul>
                <CollapsedLesson
                  number="1"
                  title="Wybór szpitala do porodu"
                  description="
                    Jaki szpital do porodu wybrać. Lista kryteriów wyboru
                    szpitala. O co pytać położne przed porodem. Poród w domu.
                  "
                />
                <DottedDivider />
                <CollapsedLesson
                  number="2"
                  title="Wyprawka do szpitala"
                  description="
                    Co spakować w swoją (i dziecka) torbę do szpitala. Oddzielna
                    torba dla maluszka? Rzeczy potrzebne podczas porodu i zaraz
                    po nim.
                  "
                />
                <DottedDivider />
                <CollapsedLesson
                  number="3"
                  title="Przyjęcie do szpitala"
                  description="
                    Jak zachować się na izbie przyjęć. Badania i dokumenty
                    potrzebne do przyjęcia. Badania wykonywane przy przyjęciu do
                    szpitala.
                  "
                />
              </ul>
            </SingleModule>

            <SingleModule>
              <ModuleHeader>
                <span>Moduł II</span>
                <h3 className="title is-4 is-spaced">Poród fizjologiczny</h3>
              </ModuleHeader>
              <ul>
                <CollapsedLesson
                  number="1"
                  title="Zwiastuny porodu - czy to już?"
                  description="
                     Jak odróżnić skurcze przepowiadające od porodowych. Kiedy
                    jechać do szpitala. Sytuacje niepokojące w czasie ciąży
                    wymagające zgłoszenia się do lekarza bądź szpitala.
                  "
                />
                <DottedDivider />
                <CollapsedLesson
                  number="2"
                  title="Okresy porodu"
                  description="
                     Co to jest poród fizjologiczny, kiedy się rozpoczyna.
                    Omówienie okresów porodu oraz czas ich trwania. Wpływ
                    prawidłowego oddechu na poród. Na czym polega kontakt skóra
                    do skóry. Czym jest kryzys 7 cm.
                  "
                />
                <DottedDivider />
                <CollapsedLesson
                  number="3"
                  title="Metody łagodzenia bólu"
                  description="
                     Niefarmakologiczne metody łagodzenia bólu – zalety i
                    rodzaje. Farmakologiczne metody łagodzenia bólu – ewentualne
                    skutki uboczne.
                  "
                />
                <DottedDivider />
                <CollapsedLesson
                  number="4"
                  title="Pozycje wertykalne. Plan porodu"
                  description="
                     Pozycje wertykalne - czym są i jakie mają zalety. Plan
                    porodu - dlaczego warto go napisać.
                  "
                />
                <DottedDivider />
                <CollapsedLesson
                  number="5"
                  title="Pierwsze chwile życia dziecka"
                  description="
                     Zabiegi, badania wykonywane u dziecka po porodzie. O czym
                    należy pamiętać w pierwszych tygodniach, kiedy jesteście już
                    razem w domu.
                  "
                />
              </ul>
            </SingleModule>

            <SingleModule>
              <ModuleHeader>
                <span>Moduł III</span>
                <h3 className="title is-4 is-spaced">
                  Cięcie cesarskie i porody zabiegowe
                </h3>
              </ModuleHeader>
              <ul>
                <CollapsedLesson
                  number="1"
                  title="Cięcie cesarskie"
                  description="
                     Wskazania, przebieg cięcia cesarskiego, technika, sposoby
                    znieczulenia. Jak dbać o ranę po cięciu cesarskim.
                  "
                />
                <DottedDivider />
                <CollapsedLesson
                  number="2"
                  title="Porody zabiegowe"
                  description="
                     Porody zabiegowe – kleszcze, wyciągacz próżniowy. Kiedy się
                    je wykonuje. Jak wygląda sam zabieg. Powikłania.
                  "
                />
              </ul>
            </SingleModule>

            <SingleModule>
              <ModuleHeader>
                <span>Moduł IV</span>
                <h3 className="title is-4 is-spaced">Kobieta po porodzie</h3>
              </ModuleHeader>
              <ul>
                <CollapsedLesson
                  number="1"
                  title="Fizjologia połogu"
                  description="
                     Na jakie okresy dzieli się połóg? Higiena w czasie połogu.
                    Pielęgnacja rany po nacięciu krocza.
                  "
                />
                <DottedDivider />
                <CollapsedLesson
                  number="2"
                  title="Niepokojące objawy i powikłania w połogu"
                  description="
                     Baby blues – co to jest, jakie są objawy. Depresja
                    poporodowa. Rola taty w czasie połogu. Powrót do współżycia.
                    Pierwsza miesiączka po porodzie.
                  "
                />
              </ul>
            </SingleModule>

            <SingleModule>
              <ModuleHeader>
                <span>Moduł V</span>
                <h3 className="title is-4 is-spaced">Karmienie piersią</h3>
              </ModuleHeader>
              <ul>
                <CollapsedLesson
                  number="1"
                  title="Karmienie piersią"
                  description="
                     Zalety karmienia piersią dla matki i dziecka. Zmienność
                    mleka kobiecego, pojemność żołądka noworodka. Zachowanie
                    noworodka po porodzie i w pierwszych dobach życia. Nawał –
                    jak sobie z nim radzić.
                  "
                />
                <DottedDivider />
                <CollapsedLesson
                  number="2"
                  title="Prawidłowe przystawianie dziecka do piersi"
                  description="
                     Cechy prawidłowego przystawienia dziecka do piersi.
                    Wskaźniki skutecznego karmienia. Jak nie zaburzyć
                    prawidłowego odruchu ssania u noworodka. Jak długo powinno
                    trwać karmienie piersią.
                  "
                />
                <DottedDivider />
                <CollapsedLesson
                  number="3"
                  title="Jak dbać o piersi?"
                  description="
                     Jak dbać o piersi. Przygotowanie piersi do karmienia. Jak
                    dobrać odpowiedni stanik do karmienia. Jak się odżywiać
                    podczas karmienia piersią.
                  "
                />
              </ul>
            </SingleModule>

            <SingleModule>
              <ModuleHeader>
                <span>Moduł VI</span>
                <h3 className="title is-4 is-spaced">Opieka nad noworodkiem</h3>
              </ModuleHeader>
              <ul>
                <CollapsedLesson
                  number="1"
                  title="Pielęgnacja noworodka"
                  description="
                     Pielęgnacja oczu, twarzy, kikuta pępowinowego, skóry,
                    pośladków. Kiedy obciąć pierwsze paznokcie. Jak poradzić
                    sobie z ciemieniuchą czy odparzeniem pośladków.
                  "
                />
                <DottedDivider />
                <CollapsedLesson
                  number="2"
                  title="Kąpiel noworodka, przewijanie, ubieranie"
                  description="Jak kąpać noworodka. Jak go przewijać oraz ubierać."
                />
                <DottedDivider />
                <CollapsedLesson
                  number="3"
                  title="Stany przejściowe noworodka"
                  description="Adaptacja do życia pozamacicznego. Zaburzenia adaptacyjne.
                    Stany przejściowe."
                />
                <DottedDivider />
                <CollapsedLesson
                  number="4"
                  title="Problemy okresu noworodkowego i niemowlęcego"
                  description="Płacz noworodka, kolki, dyschezja niemowlęca. Dieta matki
                    karmiącej ;)"
                />
                <DottedDivider />
                <CollapsedLesson
                  number="5"
                  title="Wizyta położnej środowiskowej"
                  description="Jak wygląda wizyta, jakie dokumenty trzeba przygotować.
                    Kiedy zgłosić się do położnej środowiskowej. W jakim czasie
                    po porodzie położna powinna odwiedzić ciebie i noworodka w
                    domu."
                />
              </ul>
            </SingleModule>
          </div>
        </div>
      </div>
    </ModulesSection>
  )
}

export default Modules
