import React from "react"
import styled from "styled-components"

const Hr = styled.hr`
  height: 6px;
  width: 40px;
  background: rgb(245, 86, 145);
  border-width: 1px;
  border-style: solid;
  border-color: red;
  border-image: initial;
  border-radius: 10px;
  margin: 20px auto;
`

const ShortDivider = () => {
  return <Hr />
}

export default ShortDivider
