import React, { useState } from "react"
import ShortDivider from "../ShortDivider"
import styled from "styled-components"
import Section from "../Section"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const RecommendationsSection = styled(Section)`
  background-color: rgb(248, 247, 250);
  color: rgb(73, 66, 96);
  padding: 20px;
`

const Recommendations = ({ hidden = false }: { hidden?: boolean }) => {
  const {
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
  } = useStaticQuery(graphql`
    query ImagesQuery {
      image1: file(relativePath: { eq: "course/recommendations/1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image2: file(relativePath: { eq: "course/recommendations/2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image3: file(relativePath: { eq: "course/recommendations/3.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image4: file(relativePath: { eq: "course/recommendations/4.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image5: file(relativePath: { eq: "course/recommendations/5.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image6: file(relativePath: { eq: "course/recommendations/6.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image7: file(relativePath: { eq: "course/recommendations/7.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image8: file(relativePath: { eq: "course/recommendations/8.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image9: file(relativePath: { eq: "course/recommendations/9.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image10: file(relativePath: { eq: "course/recommendations/10.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image11: file(relativePath: { eq: "course/recommendations/10.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const [allRecommendationsVisible, displayAllRecommendations] = useState(false)

  return (
    <RecommendationsSection
      id="recomendations"
      style={{ display: hidden ? "none" : "block" }}
    >
      <div className="container is-widescreen">
        <ShortDivider />
        <h1 className="title is-4 is-spaced has-text-font-comfortaa has-text-centered">
          Jak oceniają kurs Mamy z poprzednich edycji?
        </h1>
        <div className="columns is-vcentered">
          <div className="column is-6-desktop is-offset-3-desktop is-12-mobile">
            <div className="has-text-centered">
              Zaufaj Mamom, które już korzystały z kursu. Wszystkie wypowiedzi
              są w 100% autentyczne!
            </div>
            <div>
              <Img
                fluid={image1.childImageSharp.fluid}
                alt={'Opinia o Kursie Szkoły Rodzenia z Anną Nowak-Dudą"'}
              />
            </div>
            <div>
              <Img
                fluid={image2.childImageSharp.fluid}
                alt={'Opinia o Kursie Szkoły Rodzenia z Anną Nowak-Dudą"'}
              />
            </div>
            <div>
              <Img
                fluid={image3.childImageSharp.fluid}
                alt={'Opinia o Kursie Szkoły Rodzenia z Anną Nowak-Dudą"'}
              />
            </div>
            <div>
              <Img
                fluid={image4.childImageSharp.fluid}
                alt={'Opinia o Kursie Szkoły Rodzenia z Anną Nowak-Dudą"'}
              />
            </div>
            <div
              className="rest"
              style={!allRecommendationsVisible ? { display: "none" } : {}}
            >
              <div>
                <Img
                  fluid={image5.childImageSharp.fluid}
                  alt={'Opinia o Kursie Szkoły Rodzenia z Anną Nowak-Dudą"'}
                />
              </div>
              <div>
                <Img
                  fluid={image6.childImageSharp.fluid}
                  alt={'Opinia o Kursie Szkoły Rodzenia z Anną Nowak-Dudą"'}
                />
              </div>
              <div>
                <Img
                  fluid={image7.childImageSharp.fluid}
                  alt={'Opinia o Kursie Szkoły Rodzenia z Anną Nowak-Dudą"'}
                />
              </div>
              <div>
                <Img
                  fluid={image8.childImageSharp.fluid}
                  alt={'Opinia o Kursie Szkoły Rodzenia z Anną Nowak-Dudą"'}
                />
              </div>
              <div>
                <Img
                  fluid={image9.childImageSharp.fluid}
                  alt={'Opinia o Kursie Szkoły Rodzenia z Anną Nowak-Dudą"'}
                />
              </div>
              <div>
                <Img
                  fluid={image10.childImageSharp.fluid}
                  alt={'Opinia o Kursie Szkoły Rodzenia z Anną Nowak-Dudą"'}
                />
              </div>
              <div>
                <Img
                  fluid={image11.childImageSharp.fluid}
                  alt={'Opinia o Kursie Szkoły Rodzenia z Anną Nowak-Dudą"'}
                />
              </div>
              <div>
                <a
                  target="_blank"
                  href="https://s3.eu-central-1.amazonaws.com/pl.naszepierwszedziecko.publisher/blog/posts/15_10_1/Recenzja_kursu_od_Patrycji.pdf"
                >
                  Kliknij tutaj
                </a>{" "}
                aby przeczytać całą historię Patrycji (z powyższej opinii).
              </div>
            </div>
            <div
              style={{
                textAlign: "center",
                marginTop: "10px",
                display: allRecommendationsVisible ? "none" : "block",
              }}
            >
              <a className="" onClick={() => displayAllRecommendations(true)}>
                Pokaż więcej opinii (8)
              </a>
            </div>
            <br />
          </div>
        </div>
      </div>
    </RecommendationsSection>
  )
}

export default Recommendations
