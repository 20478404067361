import React from "react"
import ShortDivider from "../ShortDivider"
import styled from "styled-components"
import Section from "../Section"

const FaqSection = styled(Section)`
  background-color: rgb(255, 251, 250);
  color: rgb(73, 66, 96);
  padding: 20px;

  & h1 {
    color: rgb(33, 25, 59) !important;
  }
}
`

const SingleQuestion = styled.div`
  margin-bottom: 30px;

  & .question {
    margin-bottom: 10px;
    border-top: 1px solid rgb(245, 86, 145);
    border-right: 1px solid rgb(245, 86, 145);
    border-radius: 3px;
    padding: 20px 20px 10px 10px;
  }

  & .answer {
    font-size: 0.85em;
    padding-left: 20px;
  }
`

const Faq = () => {
  return (
    <FaqSection id="faq">
      <div className="container is-widescreen text-center">
        <div className="columns is-mobile">
          <div className="column is-6-desktop is-offset-3-desktop is-12-mobile">
            <ShortDivider />
            <h1 className="title is-4 is-spaced has-text-font-comfortaa">
              Najczęstsze pytania
            </h1>
            <div className="content is-medium">
              <SingleQuestion>
                <div className="has-text-weight-bold question">
                  Czy ojcowie też mogą wysłuchać kursu?
                </div>
                <p className="answer">
                  Tak, jak najbardziej. Mimo, że kurs skierowany jest
                  bezpośrednio do mam, to ojcowie także zyskają przydatną
                  wiedzę.
                </p>
              </SingleQuestion>
              <SingleQuestion>
                <div className="has-text-weight-bold question">
                  Na jakim urządzeniu mogę skorzystać z kursu?
                </div>
                <p className="answer">
                  Z panelu kursu możesz korzystać zarówno przez komputer jak i
                  smartphonie, mającym dostęp do Internetu.
                </p>
              </SingleQuestion>
              <SingleQuestion>
                <div
                  className="has-text-weight-bold question"
                  id="faq-listy-i-plany"
                >
                  Jakie listy i plany są w kursie?
                </div>
                <p className="answer">
                  <ul>
                    <li>Lista zwiastunów porodu</li>
                    <li>Listy wyprawkowe</li>
                    <li>Kryteria wyboru szpitala do porodu</li>
                    <li>
                      Badania diagnostyczne i konsultacje medyczne wykonywane u
                      kobiet w okresie ciąży
                    </li>
                    <li>Plan porodu</li>
                  </ul>
                </p>
              </SingleQuestion>
            </div>
          </div>
        </div>
      </div>
    </FaqSection>
  )
}

export default Faq
