import React from "react"
import ShortDivider from "../ShortDivider"
import styled from "styled-components"
import Section from "../Section"

const ForWhoSection = styled(Section)`
  background-color: rgb(255, 251, 250);
  color: rgb(33, 25, 59) !important;
  padding: 20px;
`

const ForWho = () => {
  return (
    <ForWhoSection id="for-who">
      <ShortDivider />
      <div className="container is-widescreen">
        <div className="columns is-vcentered">
          <div className="column is-6-desktop is-offset-3-desktop is-12-mobile">
            <div className="content is-medium has-text-black">
              <p>
                Kurs jest świetną pomocą dla każdej przyszłej Mamy (i przyszłego
                Taty)!
              </p>
              <p>A dla kogo jest moim zdaniem OBOWIĄZKOWA?</p>
              <ul>
                <li>
                  Dla Mam które mają utrudniony dojazd na zajęcia szkoły
                  rodzenia w swoim mieście
                </li>
                <li>
                  Dla Mam którym nie pasują godziny/terminy spotkań w szkole
                  rodzenia (lub nie pasują ich partnerom)
                </li>
                <li>
                  Dla Mam których partnerzy mają opory przed uczestnictwem w
                  szkole rodzenia
                </li>
                <li>
                  Dla Mam w zaawansowanej ciąży, mam które ciężko przechodzą
                  ciążę lub mam którym lekarz zalecił leżenie
                </li>
                <li>Dla Mam mieszkających za granicą</li>
                <li>
                  Dla Mam lubiących oszczędzać czas (dojazdy, oczekiwanie na
                  spóźniających się innych kursantów, problemy z parkowaniem
                  przed szpitalem, itp.)
                </li>
                <li>
                  Dla mam dla których poziom szkoła rodzenia z której usług
                  skorzystały był niewystarczający
                </li>
                <li>Dla mam które chcą wracać później do lekcji kursu</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ForWhoSection>
  )
}

export default ForWho
