import React, { ReactElement, ReactNode } from "react"
import "../../styles/course.scss"
import CourseNav from "../../components/course/Nav"
import Cover from "../../components/course/Cover"
import CourseContent from "../../components/course/sections/CourseContent"
import IssuesSection from "../../components/course/sections/Issues"
import MembersNumber from "../../components/course/sections/MembersNumber"
import JoinToCourseSection from "../../components/course/sections/JoinToCourse"
import AboutMeShort from "../../components/course/sections/AboutMeShort"
import WhoINeedToBe from "../../components/course/sections/WhoINeedToBe"
import ForWho from "../../components/course/sections/ForWho"
import Modules from "../../components/course/sections/Modules"
import CourseAdvantages from "../../components/course/sections/CourseAdvantages"
import Recommendations from "../../components/course/sections/Recommendations"
import Variants from "../../components/course/sections/Variants"
import MoneyReturn from "../../components/course/sections/MoneyReturn"
import Faq from "../../components/course/sections/Faq"
import MyStory from "../../components/course/sections/MyStory"
import Footer from "../../components/course/sections/Footer"
import SEO from "../../components/seo"
import { graphql } from "gatsby"
import TrackVisibility from "react-on-screen"

export const VisibilityTracking = ({
  children,
  category,
  action,
  label,
}: {
  children?: ReactNode
  category: string
  action: string
  label: string
}) => (
  <TrackVisibility partialVisibility once>
    {({ isVisible }) => {
      if (isVisible) {
        // @ts-ignore
        if (typeof ga !== "undefined") {
          // @ts-ignore
          ga("send", {
            hitType: "event",
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
            transport: "beacon",
          })
        }
      }

      return children
    }}
  </TrackVisibility>
)

export default function Course({ data }: { data: { file: any } }) {
  const { file } = data
  return (
    <div id={"course-container"}>
      <SEO
        title={
          "Szkoła Rodzenia z Anną Nowak-Dudą | Bez wychodzenia z domu, ponad 700 zadowolonych Mam"
        }
        description={
          "Kompletna, profesjonalna szkoła rodzenia online. Bez stresu, dojazdów i problemów. Wsparcie także po porodzie."
        }
        image={file.childImageSharp.fluid.src}
        article={false}
      />
      <CourseNav />
      <Cover />
      <CourseContent />
      <IssuesSection />
      <JoinToCourseSection text={"Pozwól sobie pomóc"} />
      <MembersNumber />
      <Recommendations hidden />
      <AboutMeShort />
      <WhoINeedToBe />
      <ForWho />
      <JoinToCourseSection
        text={"Też zapisuję się na kurs!"}
        color={"rgb(255,251,250)"}
      />
      <VisibilityTracking
        action="Element Visible"
        category="Visibility"
        label="Modules"
      >
        <Modules />
      </VisibilityTracking>
      <JoinToCourseSection
        text={"Zapisuje się na kurs!"}
        color={"rgb(255,251,250)"}
      />
      <CourseAdvantages />
      <Recommendations />
      <VisibilityTracking
        action="Element Visible"
        category="Visibility"
        label="Variants"
      >
        <Variants />
      </VisibilityTracking>
      <MoneyReturn />
      <VisibilityTracking
        action="Element Visible"
        category="Visibility"
        label="FAQ"
      >
        <Faq />
      </VisibilityTracking>
      <VisibilityTracking
        action="Element Visible"
        category="Visibility"
        label="My story"
      >
        <MyStory />
      </VisibilityTracking>
      <JoinToCourseSection
        text={"Zapisz się na kurs!"}
        color={"rgb(255,251,250)"}
      />
      <Footer />
    </div>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "course-cover.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
