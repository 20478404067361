import React from "react"
import ShortDivider from "../ShortDivider"
import styled from "styled-components"
import Section from "../Section"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const WhoINeedToBeSection = styled(Section)`
  background-color: rgb(73, 66, 96);
  color: rgb(255, 251, 250);
  padding: 0px 20px 20px;
`

const BiggerText = styled.p`
  font-size: 1.2em;
`

const WhoINeedToBe = () => {
  const { crownImage } = useStaticQuery(query)

  return (
    <WhoINeedToBeSection>
      <div className="container is-widescreen">
        <div className="columns is-vcentered">
          <div className="column is-6-desktop is-offset-3-desktop is-12-mobile">
            <div className="content has-text-centered">
              <Img
                className="image hero-image"
                alt="Korona Królewska"
                fluid={crownImage.childImageSharp.fluid}
              />
              <p className="has-text-centered has-text-font-comfortaa has-text-weight-bold">
                Czy, żeby mieć świetny poród, muszę mieć dużo pieniędzy?
              </p>
              <BiggerText className="content has-text-centered has-text-weight-bold">
                <u>Oczywiście, że nie!</u>
              </BiggerText>
            </div>
            <ShortDivider />
            <div className="content" /*style="padding: 0 20px"*/>
              <p className="content has-text-centered has-text-font-comfortaa">
                Sukces każdego porodu zależy przede wszystkim od Twojego
                nastawienia do tego momentu oraz praktycznego oraz fizycznego i
                psychicznego przygotowania.
              </p>
              <p className="has-text-centered has-text-font-comfortaa has-text-weight-bold">
                Ty też możesz mieć wsparcie profesjonalnej położnej
              </p>
              <p className="content has-text-centered has-text-font-comfortaa">
                Pomogę Ci uwierzyć w siebie oraz w swoje możliwości. Razem
                sprawimy aby poród i opieka nad dzieckiem były dla Ciebie
                najpiękniejszym przeżyciem i okresem w życiu.
              </p>
            </div>

            <section className="join-to-course">
              <div className="container is-widescreen">
                <div className="columns is-vcentered">
                  <div className="column is-6-desktop is-offset-3-desktop is-12-mobile">
                    <div className="container">
                      <div className="has-text-centered">
                        <div className="level-item level-center">
                          <a
                            href="/szkola-rodzenia-z-anna-nowak-duda/#variants"
                            type="submit"
                            className="is-multiline align-self-end button is-large is-fullwidth is-primary has-text-weight-bold has-text-font-comfortaa"
                          >
                            Chcę się zapisać!
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </WhoINeedToBeSection>
  )
}

const query = graphql`
  query {
    crownImage: file(relativePath: { eq: "queen_crown.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default WhoINeedToBe
