import React from "react"
import Section from "../Section"
import styled from "styled-components"
import { VideoContainer } from "../VideoContainer"

const CourseContent = () => {
  return (
    <SectionCourseContent id={"course-content"}>
      <div className="container is-widescreen">
        <VideoContainer className="is-hidden-tablet">
          <iframe
            id="mobile-teaser"
            src="https://player.vimeo.com/video/352914153"
            width="100%"
            height="auto"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        </VideoContainer>
      </div>
      <div className="container is-widescreen">
        <div className="columns">
          <div className="column is-6-desktop is-offset-3-desktop">
            <div className="columns is-mobile">
              <div className="column">
                <div className="has-text-centered">
                  <img loading={"lazy"} src="/icons/icn_produktywnosc.svg" />
                </div>
                <CourseContentText className="has-text-purple has-text-centered has-text-weight-bold">
                  <a
                    className="has-text-purple is-italic"
                    href="/szkola-rodzenia-z-anna-nowak-duda#modules"
                  >
                    5 godzin <br /> ciekawych lekcji
                  </a>
                </CourseContentText>
              </div>
              <div className="column">
                <div className="has-text-centered">
                  <img loading={"lazy"} src="/icons/icn_moduly.svg" />
                </div>
                <CourseContentText className="has-text-purple has-text-centered has-text-weight-bold">
                  <a
                    className="has-text-purple is-italic"
                    href="/szkola-rodzenia-z-anna-nowak-duda#modules"
                  >
                    6 modułów <br />i 20 lekcji
                  </a>
                </CourseContentText>
              </div>
              <div className="column">
                <div className="has-text-centered">
                  <img loading={"lazy"} src="/icons/icn_materialy.svg" />
                </div>
                <CourseContentText className="has-text-purple has-text-centered has-text-weight-bold">
                  <a
                    className="has-text-purple is-italic"
                    href="/szkola-rodzenia-z-anna-nowak-duda#faq"
                  >
                    Przydatne listy <br /> i plany
                  </a>
                </CourseContentText>
              </div>
            </div>
            <div className="column">
              <SeeModulesButton
                className="button"
                href="/szkola-rodzenia-z-anna-nowak-duda/#variants"
              >
                Sprawdź promocyjne ceny kursu
              </SeeModulesButton>
            </div>
          </div>
        </div>
      </div>
    </SectionCourseContent>
  )
}

export default CourseContent

const SectionCourseContent = styled(Section)`
  padding: 20px 20px;
  color: #494260;
`

const CourseContentText = styled.div`
  font-size: 1em;
  line-height: 1.25em;
  margin-top: 5px;
`

const SeeModulesButton = styled.a`
  color: #fffbfa;
  white-space: pre-wrap;
  height: auto;
  font-size: 1em;
  font-weight: bold;
  width: 100%;
  font-family: "Comfortaa", Arial, Helvetica, cursive;
  padding: 10px 20px;
  border-radius: 10px;
  background: #625783;
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, #625783),
    color-stop(52%, #574d73),
    color-stop(100%, #494160)
  );
  background: linear-gradient(to bottom, #625783 0%, #574d73 52%, #494160 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#625783', endColorstr='#494160', GradientType=0 );

  &:active,
  &:focus {
    color: #fffbfa;
  }
`
