import React from "react"
import ShortDivider from "../ShortDivider"
import styled from "styled-components"
import Section from "../Section"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { FullWidthSectionImage } from "../FullWidthSectionImage"

const MyStorySection = styled(Section)`
  background-color: rgb(248, 247, 250);
  color: rgb(73, 66, 96);
  font-family: Helvetica, sans, sans-serif;
  padding: 20px;
`

const BigParagraph = styled.p`
  font-size: 1.2em;
`

const MyStory = () => {
  const { annaNowakDudaImage } = useStaticQuery(query)

  return (
    <MyStorySection id="about-me">
      <div className="container is-widescreen text-center">
        <div className="columns is-mobile">
          <div className="column is-6-desktop is-offset-3-desktop is-12-mobile">
            <ShortDivider />
            <h1 className="title is-4 is-spaced has-text-font-comfortaa has-text-centered">
              Moja historia.
            </h1>
            <div className="content is-medium">
              <BigParagraph className="content has-text-centered has-text-weight-bold">
                Wpływ mojej rodziny.
              </BigParagraph>
              <p>
                Jestem położną w Klinice Neonatologii UM w Poznaniu. Pasję
                odziedziczyłam po Mamie, która także była położną na oddziale
                noworodkowym. Opowiadała mi o pięknie tego zawodu.
              </p>
              <p>
                Gdy miałam 14 lat, urodziła się moja najmłodsza siostra. Mama
                nauczyła mnie jak zajmować się takim maluszkiem. Dzięki temu
                zdobywałam doświadczenie już od najmłodszych lat.
              </p>

              <BigParagraph className="content has-text-centered has-text-weight-bold">
                Okres studiów.
              </BigParagraph>
              <p>
                Kierunek ten jest bardzo wymagający i bardzo trudny. Mimo to
                ukończyłam trzyletni licencjat oraz dwuletnią magisterkę. Dobrze
                pamiętam, że zajęcia niejednokrotnie trwały cały dzień. Praktyki
                podobnie. Dodatkowo bywały też one nocne. Miało to na celu
                przyzwyczajenie nas do pracy na zmiany.
              </p>
              <p>
                Godziny praktyczne w szpitalu nie dotyczyły wyłącznie praktyk na
                sali porodowej czy oddziale położniczym. To także mnóstwo godzin
                na wielu innych oddziałach jak np. chirurgicznym . Oprócz tego w
                weekendy pracowałam jako opiekunka do dzieci i kelnerka. Dzięki
                temu nauczyłam się dyscypliny i samozaparcia, a także szacunku
                do pracy.
              </p>

              <BigParagraph className="content has-text-centered has-text-weight-bold">
                Podnoszenie kwalifikacji.
              </BigParagraph>
              <p>
                W dalszym ciągu rozwijam się i zdobywam doświadczenie na różne
                sposoby:
              </p>
              <ul>
                <li>Stałam się edukatorem ds. laktacji</li>
                <li>Zostałam certyfikowanym instruktorem masażu Shantala</li>
                <li>
                  Skończyłam kursy specjalistyczne: leczenie ran dla położnych,
                  resuscytacja krążeniowo-oddechowa noworodka oraz dorosłego
                </li>
                <li>
                  Skończyłam kurs kwalifikacyjny „pielęgniarstwo rodzinne dla
                  położnych Uczestniczę w licznych konferencjach i szkoleniach
                </li>
                <li>Jestem ambasadorką idei 1000 pierwszych dni</li>
                <li>
                  Dzielę się także swoją wiedzą na różnych wydarzeniach czy
                  szkoleniach dla rodziców czy położnych.
                </li>
              </ul>

              <BigParagraph className="content has-text-centered has-text-weight-bold">
                Czym zajmuję się obecnie?
              </BigParagraph>
              <p>
                Powróciłam do pracy w szpitalu na oddziale neonatologicznym.
                Aczkolwiek nie zaprzestałam pracy w terenie [szkole rodzenia czy
                pomoc w domowych warunkach], bo kontakt z drugim człowiekiem
                sprawia mi ogromną radość. Oprócz tego pracuję również jako
                położna w żłobku. Tak z kolei kontroluję zdrowie dzieci od 6
                miesiąca do 3 roku życia.
              </p>

              <p>Wooow! Dotarłaś aż tutaj ;)</p>
              <div className="has-text-centered">
                <img
                  alt={"Flower"}
                  className="has-text-centered"
                  src="/flower.svg"
                />
                <p className="has-text-primary title is-5 has-text-font-comfortaa">
                  DZIEKUJĘ
                </p>
              </div>
              <FullWidthSectionImage>
                <Img
                  alt="Anna Nowak-Duda"
                  fluid={annaNowakDudaImage.childImageSharp.fluid}
                />
              </FullWidthSectionImage>
            </div>
          </div>
        </div>
      </div>
    </MyStorySection>
  )
}

export default MyStory

const query = graphql`
  query {
    annaNowakDudaImage: file(relativePath: { eq: "PANA8065.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
