import React from "react"
import Section from "../Section"
import styled from "styled-components"

const MembersNumberSection = styled(Section)`
  background-color: #f8f7fa;
  padding-bottom: 40px;
`

const MembersNumber = () => {
  return (
    <MembersNumberSection>
      <div className="content is-medium">
        <p className="has-text-centered">
          <a href="/szkola-rodzenia-z-anna-nowak-duda/#recomendations">
            Dołącz do ponad 1323 zadowolonych mam!
          </a>
        </p>
      </div>
    </MembersNumberSection>
  )
}

export default MembersNumber
