import React from "react"
import styled from "styled-components"
import Section from "../Section"
import ShortDivider from "../ShortDivider"
import App from "npd-course-shop"

const VariantsSection = styled(Section)`
  background-color: rgb(255, 251, 250);
  color: rgb(73, 66, 96);
  padding: 20px 20px 120px;
`

const CourseButton = styled.a`
  @media only screen and (min-device-width: 320px) and (max-device-width: 500px) {
    font-size: 1.35rem !important;
    padding-bottom: calc(1.2em - 1px);
    padding-left: 0.85em;
    padding-right: 0.85em;
    padding-top: calc(1.2em - 1px);
    height: auto;
    font-family: "Comfortaa", Arial, Helvetica, cursive;
    font-weight: bold;
  }

  @media only screen and (min-device-width: 310px) and (max-device-width: 330px) {
    font-size: 1rem !important;
  }
`

const Variants = () => {
  return (
    <VariantsSection id="variants">
      <div className="container is-widescreen">
        <div className="columns is-mobile">
          <div className="column is-6-desktop is-offset-3-desktop is-12-mobile">
            <ShortDivider />
            <h1 className="title is-4 is-spaced has-text-font-comfortaa variants-header has-text-centered">
              Od Listopada 2021 , kurs jest dostępny w ramach Klubu Szczęśliwej
              Mamy
            </h1>
            <CourseButton
              href="https://klubszczesliwejmamy.pl/?utm_source=naszepierwszedziecko&utm_medium=www&utm_campaign=kurs-szkoly-rodzenia"
              type="submit"
              className="is-multiline mb-6 mt-6 block align-self-end button is-large is-fullwidth is-primary has-text-weight-bold has-text-font-comfortaa"
            >
              Sprawdź Klub Szczęśliwej Mamy
            </CourseButton>
          </div>
        </div>
      </div>
      <div className="container is-widescreen">
        <div className="columns is-mobile">
          <div className="column is-6-desktop is-offset-3-desktop is-12-mobile has-text-centered">
            <p className="has-text-centered">Nie jesteś jeszcze pewna?</p>
            <p className="has-text-centered">NIE KUPUJ KOTA W WORKU!</p>
            <p className="has-text-centered">
              <a href="/szkola-rodzenia-z-anna-nowak-duda/lekcja-demo/">
                Zobacz jedną z lekcji kursu!
              </a>
            </p>
          </div>
        </div>
      </div>
    </VariantsSection>
  )
}

export default Variants
