import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown } from "@fortawesome/free-solid-svg-icons"
import { VideoContainer } from "./VideoContainer"
import Img from "gatsby-image"

const MobileCover = styled.div`
  margin-top: -20px;
  height: 65vh;

  @media screen and (min-width: 769px) {
    display: none !important;
  }
`

const DesktopCover = styled.div`
  @media screen and (max-width: 768px) {
    display: none !important;
  }
`

const CoverTextContainer = styled.div`
  align-self: flex-end;
  margin-top: -50px;
  @media screen and (min-width: 769px) {
    margin-top: 0;
  }
  padding: 10px;

  & span {
    font-size: 1.3rem;
  }
`

const CoverTextBottom = styled.div`
  padding-bottom: 10px;
`

const CoverTextBottomSmall = styled.div`
  padding-bottom: 10px;
  & span {
    font-size: 1.2rem;
  }
`

const ArrowContainer = styled.div`
  padding-bottom: 10px;

  & i {
    font-size: 30px;
    margin-top: 10px;
  }
`

const Cover = () => {
  const { mobileCover } = useStaticQuery(query)

  return (
    <>
      <MobileCover>
        <Img fluid={mobileCover.childImageSharp.fluid} />
      </MobileCover>
      <DesktopCover className="has-text-centered">
        <div className="container is-widescreen has-text-centered">
          <VideoContainer>
            <iframe
              id="desktop-teaser"
              src="https://player.vimeo.com/video/352914153"
              width="100%"
              loading="lazy"
              height="auto"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </VideoContainer>
        </div>
      </DesktopCover>
      <CoverTextContainer className="container is-widescreen cover-text">
        <div className="columns is-vcentered">
          <div className="column is-6-desktop is-offset-3-desktop">
            <h2 className="subtitle is-4 has-strong-font-comfortaa has-text-centered">
              <span className="has-text-primary has-text-weight-bold has-text-font-comfortaa">
                Przestań bać się bólu i tego, czy poradzisz sobie z opieką nad
                maluszkiem.
              </span>
            </h2>
            <CoverTextBottom className="has-text-centered">
              <span className="has-text-font-comfortaa">
                Teraz profesjonalna położna jest do Twojej dyspozycji. Kiedy Ty
                masz czas.
              </span>
            </CoverTextBottom>
            <CoverTextBottomSmall className="has-text-centered">
              <span className="has-text-font-comfortaa">
                Nie jesteś też nigdy sama - nasze ekspertki i inne Mamy zawsze
                chętnie Ci doradzą. Wszystko to w bezpiecznym i przyjaznym
                środowisku.
              </span>
            </CoverTextBottomSmall>
          </div>
        </div>
      </CoverTextContainer>
      <ArrowContainer className="has-text-centered">
        <a href="#course-content" id="scroll-to-video">
          <FontAwesomeIcon className={"has-text-primary"} icon={faArrowDown} />
        </a>
      </ArrowContainer>
    </>
  )
}

export default Cover

const query = graphql`
  query {
    mobileCover: file(relativePath: { eq: "mobile-cover.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
