import React from "react"
import Section from "../Section"
import ShortDivider from "../ShortDivider"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { FullWidthSectionImage } from "../FullWidthSectionImage"

const AboutMeShort = () => {
  const { annaNowakDudaImage, poloznaNaMedalImage } = useStaticQuery(query)

  return (
    <AboutMeSection>
      <div className="container is-widescreen">
        <div className="columns is-vcentered">
          <div className="column is-6-desktop is-offset-3-desktop is-12-mobile">
            <div className="content is-medium has-text-black">
              <ShortDivider />
              <h4 className="title is-4 has-text-centered has-text-font-comfortaa">
                Kim jestem i jak Tobie też mogę pomóc?
              </h4>
              <FullWidthSectionImage className="is-hidden-desktop">
                <Img
                  className="image hero-image"
                  alt="Anna Nowak-Duda"
                  fluid={annaNowakDudaImage.childImageSharp.fluid}
                />
              </FullWidthSectionImage>
              <p>
                Nazywam się <strong>Ania Nowak-Duda</strong> i jako położna
                pomagam przyszłym oraz świeżo upieczonym Mamom zmierzyć się ze
                wszystkimi wyzwaniami.
              </p>
              <h4 className="title is-4 has-text-centered has-text-font-comfortaa">
                Położna i tak jak Ty – mama
              </h4>
              <p>
                Po studiach i pracy w szpitalu - sama zostałam Mamą! Kiedy na
                świecie pojawił się Franek potrzebowałam wolniejszego tempa niż
                to w szpitalu. Wtedy zmieniłam kierunek - zostałam położną
                rodzinną i zaczęłam pracę w szkole rodzenia.
              </p>
              <h4 className="title is-4 has-text-centered has-text-font-comfortaa">
                Położna na medal przygotuje Cię do roli mamy
              </h4>
              <p>
                Odkryłam, że tutaj również mogę się spełniać! Prowadziłam
                zajęcia dla przyszłych rodziców, wprowadzałam ich w świat
                macierzyństwa (i tacierzyństwa). Dzieliłam się z nimi swoją
                wiedzą na temat ciąży, porodu, połogu czy opieki nad
                noworodkiem. Następnie, gdy dzieci się już urodziły, z moimi
                mamami spotykałam się na wizytach patronażowych po porodzie.
              </p>
              <p>
                <strong>
                  Mamy, którymi się opiekowałam doceniły moją pracę - dzięki nim
                  zostałam "Położną na medal" woj. Wielkopolskiego w 2017 i 2018
                  roku!
                </strong>
              </p>
              <FullWidthSectionImage>
                <Img
                  className="image hero-image"
                  alt="Anna Nowak-Duda - Położna Na Medal"
                  fluid={poloznaNaMedalImage.childImageSharp.fluid}
                />
              </FullWidthSectionImage>
              <h4 className="title is-4 has-text-centered has-text-font-comfortaa">
                Pół tysiąca szczęśliwych mam – teraz czas na Ciebie
              </h4>
              <p>
                Franek jest już większy - więc teraz znowu pracuję na oddziale
                neonatologicznym w szpitalu Ginekologiczno- Położniczym przy
                ulicy Polnej w Poznaniu. Świadomość tego, że potrafię pomagać
                innym Mamom w tym trudnym i ważnym dla nich czasie, skłoniła
                mnie do stworzenia tego kursu.
              </p>
              <p>
                Wiem, że nie masz czasu, czasami pieniędzy na spotkania z
                profesjonalną położną. Właśnie dlatego powstała moja szkoła.
              </p>
              <p>
                <strong>
                  Stworzyłam moją szkołę rodzenia, bo uważam, że każda przyszła
                  i świeża mama zasługuje na wsparcie wykwalifikowanej położnej.
                  Każda – niezależnie od możliwości czasowych oraz finansowych.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </AboutMeSection>
  )
}

export default AboutMeShort

const query = graphql`
  query {
    annaNowakDudaImage: file(relativePath: { eq: "anna-nowak-duda.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    poloznaNaMedalImage: file(relativePath: { eq: "polozna-na-medal.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

const AboutMeSection = styled(Section)`
  background-color: rgb(255, 251, 250);
  color: rgb(245, 86, 145);
  padding: 40px 20px;

  @media screen and (max-width: 768px) {
    padding-top: 0px !important;
  }

  & p {
    & strong {
      font-size: 22px;
      color: rgb(73, 66, 96);
    }
  }
`
