import React from "react"
import styled from "styled-components"
import Section from "../Section"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const MoneyReturnSection = styled(Section)`
  background-color: rgb(73, 66, 96);
  color: rgb(255, 251, 250);
  text-align: center;
  padding: 20px;
`

const BasketImage = styled(Img)<{ fluid: any }>`
  margin-top: -120px;
`

const MoneyReturn = () => {
  const { file } = useStaticQuery(query)

  return (
    <MoneyReturnSection id="money-return">
      <div className="container is-widescreen">
        <div className="columns is-mobile">
          <div className="column is-6-desktop is-offset-3-desktop is-12-mobile">
            <BasketImage fluid={file.childImageSharp.fluid} />
            <p className={"mb-4"}>
              Jeśli po zakupie jednak stwierdzisz, że taka metoda nauki nie jest
              dla Ciebie - nic straconego.
            </p>
            <p className="has-text-weight-bold">
              Masz 14 dni na rezygnację, a ja natychmiast zwracam Ci Twoje
              pieniądze.
            </p>
          </div>
        </div>
      </div>
    </MoneyReturnSection>
  )
}

export default MoneyReturn

const query = graphql`
  query {
    file: file(relativePath: { eq: "basket.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
